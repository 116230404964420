import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Token from "../Token";
import View from "../View";

export type DividerProps = {
  style?: StyleProp<ViewStyle>;
  subtle?: boolean;
};

export default function Divider(props: DividerProps) {
  const { style, subtle } = props;

  const rootStyle = [styles.root, subtle && styles.subtle, style];

  return (
    <View
      // @ts-expect-error separator does not exist in typing
      accessibilityRole="separator"
      style={rootStyle}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    alignSelf: "stretch",
    height: Token.borderWidth.thick,
    backgroundColor: Token.color.lightSecondary,
  },
  subtle: {
    height: Token.borderWidth.thin,
    backgroundColor: Token.color.lightNeutral,
  },
});
