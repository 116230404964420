"use client";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Amplify, Auth as AwsAuth } from "aws-amplify";
import { remove, set } from "es-cookie";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import config from "@ctv/core/config";

import { isOffline, useLocalizedRouterServer } from "..";

import Permission from "./permission";
import { Auth, Country, PaymentMethod, User } from "./types";

Amplify.configure(config.amplify);

const COGNITO_COOKIE_KEY = "awscognito.is.authenticated";
const AuthContext = createContext<Auth | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function CognitoAuthProvider(props: Props) {
  const { children } = props;

  const router = useLocalizedRouterServer();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const isAuthenticated = !!user;

  const getToken = useCallback(
    async () => (await AwsAuth.currentSession())?.getIdToken()?.getJwtToken(),
    []
  );

  function applyUser(cognitoUser: CognitoUserSession) {
    const user = cognitoUser.getIdToken().decodePayload() ?? null;

    if (!user) {
      return;
    }

    const {
      "https://tvlk/permissions": permissions,
      "https://tvlk/groups": groups,
      "https://tvlk/name": name,
      iat,
    } = user;

    set(COGNITO_COOKIE_KEY, "true");

    const [
      corporateId,
      corporateCode,
      corporateName,
      corporateAlias,
      corporateStatus,
      rawGlobalTripRequestApproval,
      rawPrivateTripRequestApproval,
      paymentMethod,
      country,
    ] = JSON.parse(groups);
    const permissionMap = (JSON.parse(permissions) as Permission[]).reduce(
      (obj, p) => {
        obj[p] = true;

        return obj;
      },
      {} as Record<Permission, true>
    );

    // The backend will return any value based on the config, there are 4 possible values:
    // 1. Global false
    // 2. Global true
    // 3. Private false
    // 4. Private true
    //
    // There are only 3 expected state for Approval System:
    // 1. Global false & Private false
    // 2. Global true & Private false
    // 3. Global true & Private true
    //
    // THIS IS NOT ALLOWED: Global false & Private true
    const globalTripRequestApproval = rawGlobalTripRequestApproval === "true";
    const privateTripRequestApproval =
      globalTripRequestApproval && rawPrivateTripRequestApproval === "true";

    setUser({
      name: name,
      email: user.email,
      has(...permissions: Permission[]) {
        return permissions.some((p) => permissionMap[p]);
      },
      corporateId,
      corporateCode,
      corporateName,
      corporateAlias,
      corporateStatus,
      globalTripRequestApproval,
      privateTripRequestApproval,
      loginTime: Number(iat) * 1000,
      paymentMethod,
      country,
    });
  }

  function clearSession() {
    setUser(undefined);
    remove(COGNITO_COOKIE_KEY);
  }

  const login = useCallback(() => {
    router.push(`/login`);
  }, [router]);

  const logout = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }

    AwsAuth.signOut().then(() => clearSession());
  }, [isAuthenticated]);

  useEffect(() => {
    AwsAuth.currentSession()
      .then((res) => {
        if (res) {
          applyUser(res);
        }
      })
      .catch(() => clearSession())
      .finally(() => setIsLoading(false));
  }, []);

  const value = isAuthenticated
    ? {
        getToken,
        applyUser,
        isAuthenticated,
        isLoading,
        login,
        logout,
        user,
      }
    : {
        getToken,
        applyUser,
        isAuthenticated,
        isLoading,
        login,
        logout,
        user: undefined,
      };

  if (isOffline) {
    value.user = devOfflineUser;
    value.isAuthenticated = true;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useCognitoAuth() {
  const value = useContext(AuthContext);

  if (value === undefined) {
    throw new Error("`AuthContext` could not find any provider");
  }

  return value;
}

const devOfflineUser = {
  name: "Development Offline User",
  email: "offline.user@development.com",
  has(..._permissions: Permission[]) {
    return true;
  },
  corporateId: "1",
  corporateCode: "OFFLINE CODE",
  corporateName: "Offline Company",
  corporateAlias: "Offline Alias",
  corporateStatus: "ACTIVE",
  globalTripRequestApproval: false,
  privateTripRequestApproval: false,
  loginTime: 0,
  paymentMethod: PaymentMethod.INVOICE,
  country: Country.ID,
};
