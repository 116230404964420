"use client";

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth, useClientApi } from "@ctv/core";

import { GET_PROFILE, ProfileResponse } from "./apis";

const ProfileContext = createContext<ProfileResponse | null>(null);

export default function ProfileProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const { isAuthenticated } = useAuth();

  const getProfile = useClientApi<ProfileResponse>({
    domain: "management",
    method: "post",
    path: GET_PROFILE,
  });

  useEffect(() => {
    if (isAuthenticated) {
      getProfile({}).then((res) => {
        if (res.success) {
          setProfile(res.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when isAuthenticated changes
  }, [isAuthenticated]);

  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  return useContext(ProfileContext);
}
