type Stack = {
  modalId: string;
  listener: Listener;
};

type Listener = (value: boolean) => void;

class ModalManager {
  stack: Stack[] = [];

  private notify() {
    if (this.empty) {
      return;
    }

    const activeModalId = this.stack[this.stack.length - 1].modalId;
    this.stack.forEach((stack) => {
      stack.listener(stack.modalId === activeModalId);
    });
  }

  get empty() {
    return this.stack.length === 0;
  }

  isTopModal(modalId: string) {
    return !this.empty && this.stack[0].modalId === modalId;
  }

  isDuplicate(modalId: string) {
    return this.stack.some((curr) => curr.modalId === modalId);
  }

  add(modalId: string, listener: Listener) {
    if (this.isDuplicate(modalId)) return;
    this.stack.push({ modalId, listener });
    this.notify();
  }

  remove(modalId: string) {
    const index = this.stack.findIndex((modal) => modal.modalId === modalId);
    if (index !== -1) {
      this.stack[index].listener(false);
      this.stack.splice(index, 1);
      this.notify();
    }
  }
}

const modalManager = new ModalManager();

export default modalManager;
