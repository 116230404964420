"use client";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { formatMessage } from "@ctv/shared/utils/intl";

import Cross from "@traveloka/icon-kit-web/react/IcSystemCrossClose16";

import Checkbox from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import AZGroupScroll from "./AZGroupScroll";

export type AZData = {
  text: string;
  value: string;
};
type Props = {
  type: "form" | "view";
  data: string[] | AZData[];
  content: {
    unselectedTitle: string;
    selectedTitle: string;
  };
  CustomHeader?: ReactNode;
  onPress?(items: AZData[]): void;
};

export default function AZTable(props: Props) {
  const { content, type, CustomHeader, onPress } = props;
  const [selectedData, setSelectedData] = useState<AZData[]>([]);

  const cr = CRQuery.CorporateCommon;

  const data = useMemo(
    () =>
      props.data.map((item) => {
        if (typeof item === "string") {
          return convertStringToData(item);
        }
        return item;
      }),
    [props.data]
  );

  const onPressCheckbox = useCallback(
    (item: AZData) => {
      const isSelected = selectedData.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (isSelected) {
        setSelectedData(
          selectedData.filter(
            (selectedItem) => selectedItem.value !== item.value
          )
        );
      } else {
        setSelectedData([...selectedData, item]);
      }
    },
    [selectedData]
  );

  const isSelectedAll = selectedData.length === data.length;

  return (
    <View style={styles.wrapper} row spacing="l">
      <View style={styles.container}>
        <View style={styles.selectedHeaderContainer}>
          <View row justify="between">
            <Text variant="ui-small-bold">
              {formatMessage(content.selectedTitle, {
                num: data.length - selectedData.length,
              })}
            </Text>
            {type === "form" && (
              <Checkbox
                checked={isSelectedAll}
                onChange={(value) => {
                  const newSelectedData = value ? data : [];
                  setSelectedData(newSelectedData);
                  onPress?.(newSelectedData);
                }}
                size="small"
              >
                <Text variant="ui-small">
                  {isSelectedAll ? cr.unselectAllText : cr.selectAllText}
                </Text>
              </Checkbox>
            )}
          </View>
          {CustomHeader}
        </View>
        <AZGroupScroll
          contentStyle={styles.content}
          data={data}
          selectedData={selectedData}
          onPress={onPressCheckbox}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.selectedHeaderContainer}>
          <Text variant="ui-small-bold">
            {formatMessage(content.unselectedTitle, {
              num: selectedData.length,
            })}
          </Text>
        </View>
        <View style={styles.content}>
          {selectedData.map((item, index) => (
            <View
              key={index}
              style={styles.item}
              row
              align="center"
              justify="between"
            >
              <Text style={index % 2 ? styles.odd : undefined}>
                {item.text}
              </Text>
              <TouchableOpacity onPress={() => onPressCheckbox(item)}>
                <Cross color={Token.color.darkSecondary} />
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

const CRQuery = {
  CorporateCommon: {
    selectAllText: "Select All",
    unselectAllText: "Unselect All",
  },
};

const styles = StyleSheet.create({
  wrapper: {
    maxHeight: 690,
  },
  container: {
    borderRadius: Token.borderRadius.normal,
    borderWidth: Token.borderWidth.thick,
    borderColor: Token.color.borderDivide,
    width: 366,
  },
  selectedHeaderContainer: {
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.s,
    borderBottomColor: Token.color.borderDivide,
    borderBottomWidth: Token.borderWidth.thick,
  },
  content: {
    overflow: "scroll",
    flex: 1,
  },
  item: {
    paddingVertical: Token.spacing.xs,
    paddingLeft: Token.spacing.s,
    paddingRight: Token.spacing.m,
  },
  odd: {
    backgroundColor: Token.color.lightStain,
  },
});

function convertStringToData(value: string): AZData {
  return {
    text: value,
    value: value,
  };
}
