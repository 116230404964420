import Link from "next/link";
import React, { ComponentPropsWithRef, PropsWithChildren } from "react";

import { useLocale, useTracker } from "@ctv/core";
import { TrackingType } from "@ctv/shared/tracking/types";

type Props = {
  disabled?: boolean;
  track?: TrackingType;
};

export default function LocalizedLink(
  props: PropsWithChildren<ComponentPropsWithRef<typeof Link> & Props>
) {
  const { disabled, track } = props;

  const locale = useLocale();
  const doTracking = useTracker();

  return (
    <Link
      {...props}
      style={{
        ...styles.link,
        ...props.style,
        ...(disabled ? styles.disabled : {}),
      }}
      href={`/${locale}/${props.href}`.replaceAll("//", "/")}
      onClick={track ? () => doTracking(track.event, track.data) : undefined}
    />
  );
}

const styles = {
  link: {
    textDecoration: "none",
  },
  disabled: {
    pointerEvents: "none" as const,
  },
};
