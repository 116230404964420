import { ReactElement } from "react";

import IntlMessageformat from "intl-messageformat";

import { Locale } from "@ctv/core";
import { Country } from "@ctv/core/auth/types";
import { getCompanyCountry } from "@ctv/core/locale/utils/country";
import { hasSupportedLocales } from "@ctv/core/locale/utils/url";
import { CurrencyValue } from "@ctv/core/utils/currency";

export function formatMessage(
  entry: string,
  params?: Record<string, string | number | ReactElement>
) {
  try {
    return new IntlMessageformat(entry, undefined, undefined, {
      ignoreTag: true,
    }).format(params) as string;
  } catch (err) {
    console.error("Missing content resource variable passed", { err });
    return entry;
  }
}

const DECIMAL_NUMBER_MAP: Record<string, number> = {
  IDR: 0,
  VND: 0,
};

export function getLocale(): Locale | undefined {
  const currentLocale = window
    ? (window.location.pathname.split("/")[1] as Locale)
    : undefined;

  return currentLocale && hasSupportedLocales(currentLocale)
    ? currentLocale
    : undefined;
}

export function getLocaleByCountry(country: Country) {
  return `en-${country.toLowerCase()}` as Locale;
}

export function getLocaleByCurrency(currency: "IDR" | "THB" | "VND") {
  const country = getCountryByCurrency(currency);
  return `en-${country.toLowerCase()}` as Locale;
}

export function getCountryByCurrency(currency: "IDR" | "THB" | "VND") {
  switch (currency) {
    case "IDR":
      return Country.ID;
    case "THB":
      return Country.TH;
    case "VND":
      return Country.VN;
  }
}

export function getCurrencyByCountry(country: Country | undefined) {
  switch (country) {
    case Country.ID:
      return "IDR";
    case Country.TH:
      return "THB";
    case Country.VN:
      return "VND";
    default:
      return "IDR";
  }
}

export function getDisplayCurrencyByCountry(country?: Country): {
  symbol: string;
  position: "left" | "right";
} {
  const companyCountry = country ?? getCompanyCountry();

  switch (companyCountry) {
    case Country.ID:
      return { symbol: "Rp", position: "left" };
    case Country.TH:
      return { symbol: "THB", position: "left" };
    case Country.VN:
      return { symbol: "VND", position: "right" };
    default:
      return { symbol: "Rp", position: "left" };
  }
}

export function getDisplayCurrencyByLocale(locale?: Locale): {
  symbol: string;
  position: "left" | "right";
} {
  const usedLocale = locale ?? getLocale();

  switch (usedLocale) {
    case Locale.ENID:
    case Locale.IDID:
      return { symbol: "Rp", position: "left" };
    case Locale.ENTH:
    case Locale.THTH:
      return { symbol: "THB", position: "left" };
    case Locale.ENVN:
    case Locale.VNVN:
      return { symbol: "VND", position: "right" };
    case undefined:
      return { symbol: "Rp", position: "left" };
  }
}

export function formatCurrency(currencyValue: CurrencyValue) {
  const { amount, currency, decimalPoints } = currencyValue;
  const overrideDecimal = DECIMAL_NUMBER_MAP[currency] ?? decimalPoints;

  const formatter = new Intl.NumberFormat("id-ID", {
    currency,
    minimumFractionDigits: overrideDecimal,
    maximumFractionDigits: overrideDecimal,
  });
  // convert &nbsp; into space
  const formattedAmount = formatter.format(amount).replace("\u00a0", " ");

  if (currency === "THB") {
    return `${currency} ${formattedAmount}`;
  }

  if (currency === "VND") {
    return `${formattedAmount} ${currency}`;
  }

  // Fallback Format
  const defaultFormatter = new Intl.NumberFormat("id-ID", {
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: overrideDecimal,
    maximumFractionDigits: overrideDecimal,
    style: "currency",
  });
  // convert &nbsp; into space
  return defaultFormatter.format(amount).replace("\u00a0", " ");
}
