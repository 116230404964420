import { PropsWithChildren } from "react";

import HeaderHeightProvider from "@ctv/shared/components/Layout/HeaderHeightContext";
import FadePortalProvider from "@ctv/shared/contexts/FadePortalContext";
import { SnackbarProvider } from "@ctv/shared/contexts/SnackbarContext";

import { AuthProvider } from "../auth";
import { FeatureControlProvider } from "../feature-control/FeatureControlContext";
import { Locale } from "../locale/constants";
import LocaleProvider from "../locale/contexts/LocaleContext";
import { ResourceProvider, Resources } from "../resource";

import { AccountStatusProvider } from "./account-status/AccountStatusContext";
import ClientInterfaceProvider from "./client-interface/ClientInterfaceContext";
import { CreditLimitProvider } from "./credit-limit/CreditLimitContext";
import ProfileProvider from "./profile/ProfileContext";
import QueryClientProvider from "./react-query/QueryClientProvider";
import Wrapper from "./Wrapper";

type Props = {
  resources: Resources;
  clientInterface: "mobile" | "desktop";
  locale: Locale;
};

export default function Providers({
  children,
  resources,
  clientInterface,
  locale,
}: PropsWithChildren<Props>) {
  return (
    <LocaleProvider locale={locale}>
      <AuthProvider>
        <FeatureControlProvider>
          <ResourceProvider resources={resources}>
            <ClientInterfaceProvider clientInterface={clientInterface}>
              <CreditLimitProvider>
                <ProfileProvider>
                  <QueryClientProvider>
                    <HeaderHeightProvider>
                      <SnackbarProvider>
                        <AccountStatusProvider>
                          <FadePortalProvider>
                            <Wrapper>{children}</Wrapper>
                          </FadePortalProvider>
                        </AccountStatusProvider>
                      </SnackbarProvider>
                    </HeaderHeightProvider>
                  </QueryClientProvider>
                </ProfileProvider>
              </CreditLimitProvider>
            </ClientInterfaceProvider>
          </ResourceProvider>
        </FeatureControlProvider>
      </AuthProvider>
    </LocaleProvider>
  );
}
