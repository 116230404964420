import { ClientInterface } from "../providers/client-interface/ClientInterfaceContext";

export type Method = "post" | "get";

export type Domain =
  | "search"
  | "booking"
  | "management"
  | "content"
  | "refund"
  | "data";

export type ApiOption<Request = unknown> = {
  method: Method;
  domain: Domain;
  path: string;
  withAuth?: boolean;
  payload?: Request;
  localePrefix?: boolean;
};

export type ServerApiOption<Request = unknown> = ApiOption<Request> & {
  // Will need to remove the optional typing later (separate improvement)
  clientInterface?: ClientInterface;

  // For logging purpose
  showLog?: boolean;

  // Cache time in seconds
  cacheTime?: number; // in seconds
};

export enum ResponseStatus {
  OK = "OK",
  NO_DATA_FOUND = "NO_DATA_FOUND",
  FAILED_TO_RETRIEVE_DATA = "FAILED_TO_RETRIEVE_DATA",
  INVALID_SPEC = "INVALID_SPEC",
}

export enum ResponseError {
  BAD_REQUEST = "BAD_REQUEST",
  API_CALL_NO_LONGER_SUPPORTED = "API_CALL_NO_LONGER_SUPPORTED",
  UNDER_MAINTENANCE = "UNDER_MAINTENANCE",
  SERVER_ERROR = "SERVER_ERROR",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

type ErrorApiResponse = {
  errorMessage: string;
  userErrorMessage: string;
  errorType: ResponseError;
};

type FailedApiResponse = {
  status: OmitTyped<typeof ResponseStatus, ResponseStatus.OK>;
  errorMessage: string;
};

type SuccessApiResponse<T> = {
  data: T;
  status: ResponseStatus.OK;
  trackingSpec: Nullable<{ id: string }>;
};

export interface ApiError extends Error {
  type: FailedApiResponse["status"] | ErrorApiResponse["errorType"];
}

type FailedResult = {
  success: false;
  error: Error | ApiError;
};

type SuccessResult<T> = {
  success: true;
  data: T;
  trackingSpec: Nullable<{ id: string }>;
};

export type ApiResponse<T> =
  | ErrorApiResponse
  | FailedApiResponse
  | SuccessApiResponse<T>;

export type ApiResult<T> = FailedResult | SuccessResult<T>;
