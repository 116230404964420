"use client";
import { cloneElement, forwardRef, ReactElement } from "react";
import { AccessibilityRole, StyleSheet } from "react-native";

import DotLoader from "../DotLoader";
import { useHover, usePress } from "../hooks";
import { TextProps, TextSeo } from "../Text";
import Token from "../Token";
import { ViewSeo } from "../View";

import "./Button.css";
import cssStyles from "./Button.module.css";

type Variant =
  | "primary"
  | "secondary"
  | "main-cta"
  | "destructive-primary"
  | "destructive-secondary"
  | "outline-black"
  | "outline-white"
  | "outline-primary"
  | "floating-primary"
  | "floating-secondary"
  | "floating-main-cta"
  | "floating-outline-black"
  | "floating-outline-white"
  | "text-primary"
  | "text-destructive"
  | "text-black"
  | "text-white";

type Size = "small" | "medium" | "large";

export type ButtonSeoProps = {
  /**
   * Overrides the text that's read by the screen reader when the user
   * interacts with the element
   */
  accessibilityLabel?: string;
  accessibilityRole?: AccessibilityRole;
  /**
   * Styling for the button
   */
  buttonClassName?: string;
  /**
   * Custom style applied to the root element
   */
  className?: string;
  /**
   * Controls whether the button is disabled or not
   * @default false
   */
  disabled?: boolean;
  /**
   * Element placed after the children.
   */
  endIcon?: ReactElement;
  /**
   * Display DotLoader as replacement of the content
   * @default false
   */
  loading?: boolean;
  /**
   * Called when the button is pressed
   */
  onPress?(): void;
  /**
   * Called after the button is pressed (mouse up)
   */
  onBlur?(): void;
  /**
   * Defines size of the button
   * @default 'medium'
   */
  size?: Size;
  /**
   * Element placed before the children.
   */
  startIcon?: ReactElement;
  /**
   * The content of the button
   */
  text?: string;
  /**
   * Custom text ink color
   */
  textInk?: {
    normal: TextProps["ink"];
    disabled: TextProps["ink"];
    hovered: TextProps["ink"];
  };
  /**
   * Defines variant of the button
   * @default 'primary'
   */
  variant?: Variant;
  /**
   * Used to locate this view in end-to-end tests.
   */
  testID?: string;
};

const iconSizeMap = {
  small: 12,
  medium: 16,
  large: 24,
};

export type ButtonRef = HTMLButtonElement;

export default forwardRef<ButtonRef, ButtonSeoProps>(function ButtonSeo(
  props,
  ref
) {
  const {
    accessibilityLabel,
    accessibilityRole = "button",
    buttonClassName,
    disabled = false,
    endIcon,
    loading = false,
    onPress,
    onBlur,
    size = "medium",
    startIcon,
    className,
    testID,
    text,
    textInk: textInkProps,
    variant = "primary",
    ...rest
  } = props;

  const [hovered, hoverHandler] = useHover();
  const [pressed, pressHandler] = usePress();

  function handlePress() {
    if (typeof onPress === "function") onPress();
  }
  let textInk = textInkMap[variant];
  if (textInkProps) {
    textInk = textInkProps.normal;
    if (disabled) {
      textInk = textInkProps.disabled;
    } else if (hovered) {
      textInk = textInkProps.hovered;
    }
  } else {
    if (disabled) {
      textInk = disabledTextInkMap[variant];
    } else if (hovered) {
      textInk = hoveredTextInkMap[variant];
    }
  }

  const containerStyle = [
    cssStyles.root,
    cssStyles[variant],
    containerClassNameMap[variant],
    className,
  ];

  const innerStyle = [
    cssStyles.inner,
    pressed && cssStyles.innerFade,
    loading && cssStyles.hidden,
    buttonClassName,
  ];

  return (
    <button
      ref={ref}
      disabled={loading || disabled}
      onClick={handlePress}
      onBlur={onBlur}
      role="button"
      type="button"
      className={containerStyle.join(" ")}
      {...hoverHandler}
      {...pressHandler}
    >
      <ViewSeo align="center" row spacing="xs" className={innerStyle.join(" ")}>
        {resizeIcon(startIcon, size)}
        {typeof text === "string" && (
          <TextSeo
            className={[cssStyles.buttonText, cssStyles[size]].join(" ")}
            ink={textInk}
          >
            {text}
          </TextSeo>
        )}
        {resizeIcon(endIcon, size)}
      </ViewSeo>
      {loading && (
        <DotLoader
          color={loadingColorMap[variant] ?? Token.color.darkSecondary}
          style={styles.loading}
        />
      )}
    </button>
  );
});

function resizeIcon(icon: ReactElement | undefined, size: Size) {
  if (!icon) return null;

  const iconSize = iconSizeMap[size];
  return cloneElement(icon, { height: iconSize, width: iconSize });
}

const styles = StyleSheet.create({
  loading: {
    position: "absolute",
    zIndex: 1,
  },
});

const loadingColorMap: { [variant in Variant]?: string } = {
  "outline-white": Token.color.lightSecondary,
};

const containerClassNameMap: Record<Variant, string> = {
  primary: cssStyles.regularButton,
  secondary: cssStyles.regularButton,
  "main-cta": cssStyles.regularButton,
  "destructive-primary": cssStyles.regularButton,
  "destructive-secondary": cssStyles.regularButton,
  "floating-primary": `${cssStyles.regularButton} ${cssStyles.floatingButton}`,
  "floating-secondary": `${cssStyles.regularButton} ${cssStyles.floatingButton}`,
  "floating-main-cta": `${cssStyles.regularButton} ${cssStyles.floatingButton}`,
  "outline-black": cssStyles.outlineButton,
  "outline-white": cssStyles.outlineButton,
  "outline-primary": cssStyles.outlineButton,
  "floating-outline-black": `${cssStyles.outlineButton} ${cssStyles.floatingButton}`,
  "floating-outline-white": `${cssStyles.outlineButton} ${cssStyles.floatingButton}`,
  "text-primary": cssStyles.textButton,
  "text-destructive": cssStyles.textButton,
  "text-black": cssStyles.textButton,
  "text-white": cssStyles.textButton,
};

const textInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "white-primary",
  secondary: "interactive",
  "main-cta": "white-primary",
  "destructive-primary": "white-primary",
  "destructive-secondary": "destructive",
  "outline-black": "black-neutral",
  "outline-white": "white-neutral",
  "outline-primary": "interactive",
  "floating-primary": "white-primary",
  "floating-secondary": "interactive",
  "floating-main-cta": "white-primary",
  "floating-outline-black": "black-neutral",
  "floating-outline-white": "white-neutral",
  "text-primary": "interactive",
  "text-destructive": "destructive",
  "text-black": "black-neutral",
  "text-white": "white-neutral",
};

const hoveredTextInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "white-primary",
  secondary: "interactive",
  "main-cta": "white-primary",
  "destructive-primary": "white-primary",
  "destructive-secondary": "destructive",
  "outline-black": "black-neutral",
  "outline-white": "white-neutral",
  "outline-primary": "highlight",
  "floating-primary": "white-primary",
  "floating-secondary": "interactive",
  "floating-main-cta": "white-primary",
  "floating-outline-black": "black-neutral",
  "floating-outline-white": "white-neutral",
  "text-primary": "highlight",
  "text-destructive": "alert",
  "text-black": "black-primary",
  "text-white": "white-primary",
};

const disabledTextInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "black-muted",
  secondary: "black-muted",
  "main-cta": "black-muted",
  "destructive-primary": "black-muted",
  "destructive-secondary": "black-muted",
  "outline-black": "black-muted",
  "outline-white": "black-secondary",
  "outline-primary": "black-muted",
  "floating-primary": "black-muted",
  "floating-secondary": "black-muted",
  "floating-main-cta": "black-muted",
  "floating-outline-black": "black-muted",
  "floating-outline-white": "black-secondary",
  "text-primary": "black-muted",
  "text-destructive": "black-muted",
  "text-black": "black-muted",
  "text-white": "black-secondary",
};
