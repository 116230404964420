"use client";
import { useEffect, useRef } from "react";

// replace with @xobotyi/scrollbar-width
import { getScrollbarWidth } from "../utils";

function getRightPadding() {
  return parseInt(getComputedStyle(document.body).paddingRight, 10) || 0;
}

function isOverflowing() {
  return window.innerWidth > document.documentElement.clientWidth;
}

export default function useScrollLock(active: boolean) {
  const prevActive = useRef(false);

  useEffect(() => {
    if (active && !prevActive.current) {
      const restore: Array<{
        property: string;
        el: HTMLElement;
        value: string;
      }> = [];

      if (isOverflowing()) {
        restore.push({
          value: document.body.style.paddingRight,
          property: "padding-right",
          el: document.body,
        });

        const rightPadding = getRightPadding() + getScrollbarWidth();
        document.body.style.paddingRight = `${rightPadding}px`;
      }

      restore.push({
        value: document.body.style.overflow,
        property: "overflow",
        el: document.body,
      });
      document.body.style.overflow = "hidden";

      return () => {
        restore.forEach(({ value, property, el }) => {
          if (value) {
            el.style.setProperty(property, value);
          } else {
            el.style.removeProperty(property);
          }
        });
      };
    }

    prevActive.current = active;

    return;
  }, [active]);
}
