import { Fragment, useRef } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import AZItem from "./AZItem";
import AZScrollBar, { azList } from "./AZScrollBar";
import { AZData } from "./AZTable";

type Props = {
  data: AZData[];
  contentStyle: ViewStyle;
  selectedData: AZData[];
  onPress(item: AZData): void;
};

export default function AZGroupScroll(props: Props) {
  const { data, contentStyle, selectedData, onPress } = props;
  const azRef = useRef(Array(azList.length));

  const current = { key: "", index: -1 };

  return (
    <View style={styles.wrapper} row>
      <AZScrollBar azRef={azRef} />
      <View style={contentStyle}>
        {data.map((item, index) => {
          current.index += 1;
          const currentGroup = item.value[0];
          const isNewGroup = currentGroup !== current.key;
          if (isNewGroup) {
            current.key = item.value[0];
            current.index = -1;
          }

          const groupIndex = azList.indexOf(currentGroup);

          return (
            <Fragment key={index}>
              {isNewGroup && (
                <View ref={(el) => (azRef.current[groupIndex] = el)}>
                  <Text variant="ui-small-bold" style={styles.azGroup}>
                    {current.key}
                  </Text>
                </View>
              )}
              <AZItem
                item={item}
                onPress={onPress}
                isSelected={selectedData.includes(item)}
                style={current.index % 2 === 0 ? styles.odd : undefined}
              />
            </Fragment>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  azContainer: {
    padding: Token.spacing.s,
    borderRightColor: Token.color.borderSubtle,
    borderRightWidth: Token.borderWidth.thin,
  },
  azGroup: {
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.s,
    paddingBottom: Token.spacing.xs,
    borderBottomColor: Token.color.borderSubtle,
    borderBottomWidth: Token.borderWidth.thin,
  },
  odd: {
    backgroundColor: Token.color.lightStain,
  },
});
