import { ReactNode } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { useHover } from '../hooks';
import Text, { TextProps } from '../Text';
import { isReactText } from '../utils';
import View from '../View';
import RadioDisc from './RadioDisc';

type Size = 'small' | 'medium' | 'large';

export type RadioProps<T> = {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  children?: ReactNode;
  /**
   * Defines size of the element
   * @default 'medium'
   */
  size?: Size;
  style?: StyleProp<ViewStyle>;
  value: T;
  onChange?(value: T): void;
};

const textVariantMap: Record<Size, TextProps['variant']> = {
  small: 'ui-small',
  medium: 'ui-baseline',
  large: 'ui-large',
};

export default function Radio<T>(props: RadioProps<T>) {
  const {
    checked,
    children,
    disabled,
    error,
    onChange,
    size = 'medium',
    style,
    value,
  } = props;

  const [hovered, hoverHandler] = useHover();

  function handleChange() {
    if (typeof onChange === 'function') onChange(value);
  }

  return (
    <TouchableOpacity
      {...hoverHandler}
      activeOpacity={0.5}
      disabled={disabled}
      onPress={handleChange}
    >
      <View align="center" row spacing="xs" style={style}>
        <RadioDisc
          checked={checked}
          disabled={disabled}
          error={error}
          hovered={hovered}
          size={size}
        />
        {isReactText(children) && (
          <Text
            ink={disabled ? 'black-muted' : undefined}
            variant={textVariantMap[size]}
          >
            {children}
          </Text>
        )}
        {!isReactText(children) && children}
      </View>
    </TouchableOpacity>
  );
}
