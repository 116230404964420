import { cloneElement, isValidElement, ReactElement, ReactNode } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  TouchableOpacity,
} from "react-native";

import Cross from "@traveloka/icon-kit-web/react/IcSystemCrossClose";

import { View } from "../";
import Card from "../Card";
import { ImageProps } from "../Image";
import Modal, { ModalProps } from "../Modal";
import Text from "../Text";
import Token from "../Token";
import { isReactText } from "../utils";

type OmitModalProps = Omit<ModalProps, "render" | "duration">;

export type DialogProps = OmitModalProps & {
  children?: ReactNode;
  /**
   *
   */
  image?: ReactElement;
  maxWidth?: number | string;
  /**
   * Title of popup
   */
  title: string;
};

export default function Dialog(props: DialogProps) {
  const {
    children,
    image,
    title,
    maxWidth = 600,
    onClose,
    ...modalProps
  } = props;

  const withClose = typeof onClose === "function";

  function handleClose() {
    if (typeof onClose === "function") onClose();
  }

  function handleBackdropPress(e: GestureResponderEvent) {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  }

  return (
    <Modal
      {...modalProps}
      duration={Token.timing.instant}
      render={(state) => (
        <Pressable style={styles.scroll} onPress={handleBackdropPress}>
          <Card
            elevation="hover"
            style={[
              styles.dialog,
              styles[state],
              { maxWidth: Number(maxWidth) },
            ]}
          >
            {isValidElement<ImageProps>(image) &&
              cloneElement(image, {
                variant: "top-rounded",
                objectFit: "cover",
              })}
            <View row spacing="m" style={styles.header}>
              {isReactText(title) && <Text variant="title-1">{title}</Text>}
              {withClose && (
                <TouchableOpacity onPress={handleClose} style={styles.close}>
                  <Cross
                    color={Token.color.darkPrimary}
                    width={24}
                    height={24}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.content}>{children}</View>
          </Card>
        </Pressable>
      )}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    padding: Token.spacing.m,
  },

  scroll: {
    cursor: undefined,
    flex: 1,
    overflowY: "auto",
    padding: Token.spacing.m,
  },

  dialog: {
    margin: "auto",
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "opacity",
  },
  enter: {
    opacity: 1,
    animationTimingFunction: "ease-out",
  },
  exit: {
    opacity: 0,
    animationTimingFunction: "ease-in",
  },
  header: {
    padding: Token.spacing.m,
    paddingBottom: Token.spacing.xxs,
  },

  content: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
  },
  close: {
    marginStart: "auto",
  },
});
