"use client";
import { useEffect, useState, Dispatch, SetStateAction } from "react";

type DispatchAction<T> = Dispatch<SetStateAction<T>>;

export default function useDebounce<S>(
  initialState: S | (() => S),
  delay: number
): [S, DispatchAction<S>];
export default function useDebounce<S = undefined>(
  delay: number
): [S | undefined, DispatchAction<S | undefined>];
export default function useDebounce<S>(
  initialState: S | (() => S) | number,
  delay?: number
) {
  const [state, setState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(state);

  const normalizedDelay =
    typeof delay === "undefined" && typeof initialState === "number"
      ? initialState
      : delay!;

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedState(state), normalizedDelay);

    return () => clearTimeout(handler);
  }, [state, normalizedDelay]);

  return [debouncedState, setState];
}
