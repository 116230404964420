import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { StyleSheet, View } from 'react-native';

import Token from '../Token';
import CalendarCell, { CalendarCellProps } from './CalendarCell';
import { getDates } from './utils';

export type CalendarDayProps = {
  maxDate?: Date;
  minDate?: Date;
  month: number;
  onChange?(date: Date): void;
  value: Date;
  year: number;
};

export default function CalendarMonth(props: CalendarDayProps) {
  const { minDate, maxDate, onChange, year, month, value } = props;

  const dates = getDates(year, month);

  const today = new Date();
  const dateFormatter = new Intl.DateTimeFormat('id', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return (
    <View style={styles.root}>
      {dates.map((date, index) => {
        if (!date) {
          return (
            <View
              key={['empty', year, month, index].join('.')}
              aria-hidden
              style={styles.empty}
            />
          );
        }

        let decoration: CalendarCellProps['decoration'];
        if (isSameDay(date, value)) {
          decoration = 'selection';
        } else if (isSameDay(date, today)) {
          decoration = 'today';
        }

        const disabled =
          (!!minDate && startOfDay(minDate) > date) ||
          (!!maxDate && endOfDay(maxDate) < date);

        return (
          <CalendarCell
            key={date.getTime()}
            accessibilityLabel={dateFormatter.format(date)}
            date={date}
            decoration={decoration}
            disabled={disabled}
            onChange={onChange}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'grid' as any,
    gridTemplateColumns: 'repeat(7, 1fr)',
  },

  empty: {
    alignSelf: 'center',
    height: 24,
  },
  emptyMarked: {
    backgroundColor: Token.color.blueSecondary,
  },
});
