"use client";

import { PropsWithChildren } from "react";

import { ViewSeo } from "@ctv/momentum";

import { useAuth } from "..";

import styles from "./Wrapper.module.css";

// (michael-harley) TODO: Will need to adjust this when we implement mobile web for PostLogin pages
// Fix: https://29022131.atlassian.net/browse/CTVOPS-251
export default function Wrapper(props: PropsWithChildren<unknown>) {
  const { children } = props;

  const auth = useAuth();

  return (
    <ViewSeo
      className={`${auth.isAuthenticated ? styles.authenticated : ""} ${
        styles.wrapper
      }`}
    >
      {children}
    </ViewSeo>
  );
}
