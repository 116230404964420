import { ReactElement, ReactNode, forwardRef } from "react";
import { StyleProp, StyleSheet, TextInput, ViewStyle } from "react-native";
import Token from "../../Token";
import View from "../../View";
import {
  Input,
  InputError,
  InputHelper,
  InputLabel,
  InputProps,
  InputRef,
} from "../Input";

type TrimmedInputProps = Omit<InputProps, "error" | "style">;

export type InputFieldProps = {
  endHelper?: string;
  error?: string | boolean;
  helperId?: string;
  helperText?: ReactElement;
  inputStyle?: InputProps["style"];
  label?: string;
  labelId?: string;
  required?: boolean;
  startHelper?: string;
  style?: StyleProp<ViewStyle>;
} & TrimmedInputProps;

export type InputFieldRef = TextInput;

export default forwardRef<InputRef, InputFieldProps>(function InputField(
  props,
  ref
) {
  const {
    endHelper,
    error,
    helperId,
    helperText,
    inputStyle,
    label,
    labelId,
    required,
    startHelper,
    style,
    ...inputProps
  } = props;

  const { disabled, nativeID, size } = inputProps;

  return (
    <View spacing="xxs" style={[styles.root, style]}>
      {label && (
        <InputLabel
          disabled={disabled}
          inputId={nativeID}
          required={required}
          size={size}
          text={label}
        />
      )}
      <Input
        {...inputProps}
        ref={ref}
        error={isValidError(error) || Boolean(error)}
        helperId={helperId}
        labelId={labelId}
        style={inputStyle}
      />
      {isValidError(error) ? (
        <InputError nativeID={helperId} text={error} />
      ) : (
        helperText
      )}
      {(typeof endHelper === "string" || typeof startHelper === "string") && (
        <InputHelper
          disabled={disabled}
          endText={endHelper}
          nativeID={helperId}
          startText={startHelper}
        />
      )}
    </View>
  );
});

function isValidError(error: string | boolean | undefined): error is string {
  return typeof error === "string" && error !== "";
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Token.spacing.xs,
  },
});
