"use client";
import { addMonths, isSameMonth } from "date-fns";
import { useState } from "react";
import { StyleSheet, View } from "react-native";

import CalendarHeader from "./CalendarHeader";
import CalendarMonth from "./CalendarMonth";
import CalendarWeekday from "./CalendarWeekday";
import Token from "../Token/Token";

export type CalendarProps = {
  locale?: string;
  /**
   * Min selectable date.
   * Default to 1 year before maxDate or today.
   */
  minDate?: Date;
  /**
   * Max selectable date.
   * Default to 1 year after minDate.
   */
  maxDate?: Date;
  onNextPress?(): void;
  onPrevPress?(): void;
  value: Date;
  onChange?(value: Date): void;
};

export default function Calendar(props: CalendarProps) {
  const {
    locale,
    maxDate,
    minDate,
    onChange,
    onNextPress,
    onPrevPress,
    value,
  } = props;

  const [currentMonth, setCurrentMonth] = useState(() => {
    return value;
  });

  const formatter = new Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  });

  function handleNextPress() {
    setCurrentMonth((v) => addMonths(v, 1));

    if (typeof onNextPress === "function") onNextPress();
  }

  function handlePrevPress() {
    setCurrentMonth((v) => addMonths(v, -1));

    if (typeof onPrevPress === "function") onPrevPress();
  }

  return (
    <View style={styles.container}>
      <CalendarHeader
        nextDisabled={!!maxDate && isSameMonth(currentMonth, maxDate)}
        onNextPress={handleNextPress}
        onPrevPress={handlePrevPress}
        prevDisabled={!!minDate && isSameMonth(currentMonth, minDate)}
        text={formatter.format(currentMonth)}
      />
      <CalendarWeekday />
      <CalendarMonth
        maxDate={maxDate}
        minDate={minDate}
        month={currentMonth.getMonth()}
        onChange={onChange}
        value={value}
        year={currentMonth.getFullYear()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xxs,
    minWidth: 269,
  },
});
