import { Children, HTMLAttributes, ReactNode, forwardRef } from "react";

import Token, { Spacing } from "../Token";
import { intersperse } from "../utils";

import styles from "./View.module.css";

export type ViewSeoRef = HTMLDivElement;

export type ViewSeoProps = {
  children?: ReactNode;
  /**
   * Defines `align-items` style property.
   * @default 'stretch'
   */
  align?: "start" | "end" | "center" | "stretch" | "baseline";
  /**
   * Defines `justify-content` style property.
   * @default 'start'
   */
  justify?: "start" | "end" | "center" | "around" | "between" | "evenly";
  /**
   * Set`flex-direction` as `row-reverse` or `column-reverse`
   */
  reverse?: boolean;
  /**
   * Set `flex-direction` as `row` or `column`
   */
  row?: boolean;
  /**
   * Defines the space between the children.
   */
  spacing?: Spacing;
  /**
   * Custom class name.
   */
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

export default forwardRef<ViewSeoRef, ViewSeoProps>(function View(props, ref) {
  const { align, justify, spacing, reverse, row, className, ...rest } = props;

  let children = props.children;
  if (spacing !== undefined) {
    const property = row ? "width" : "height";
    children = intersperse(Children.toArray(props.children), (index) => (
      <div
        aria-hidden
        key={"s." + index}
        style={{ flexShrink: 0, [property]: Token.spacing[spacing] }}
      />
    ));
  }

  let directionStyle: string = "";
  if (row && reverse) {
    directionStyle = styles["direction-row-reverse"];
  } else if (row) {
    directionStyle = styles["direction-row"];
  } else if (reverse) {
    directionStyle = styles["direction-column-reverse"];
  }

  const rootStyles = [
    styles.flex,
    directionStyle,
    typeof align === "string" && styles["align-" + align],
    typeof justify === "string" && styles["justify-" + justify],
    className,
  ];

  return (
    <div {...rest} ref={ref} className={rootStyles.filter(Boolean).join(" ")}>
      {children}
    </div>
  );
});
