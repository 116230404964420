import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

import Checkbox from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import { AZData } from "./AZTable";

type Props = {
  item: AZData;
  isSelected: boolean;
  onPress(item: AZData): void;
  style?: ViewStyle;
};

export default function AZItem(props: Props) {
  const { item, isSelected, onPress, style } = props;

  return (
    <TouchableOpacity onPress={() => onPress(item)}>
      <View style={[styles.container, style]} row spacing="xs" align="center">
        <Checkbox
          checked={isSelected}
          onChange={() => onPress(item)}
          size="small"
        />
        <Text>{item.text}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
});
