"use client";
import React, { useEffect, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { StyleSheet, View, ViewStyle, findNodeHandle } from "react-native";

import { LayoutRectangle } from "@ctv/shared/hooks/useLayout";
import observeRect from "./observeRect";

type AbsoluteProps = {
  /**
   * Parent ref as the basis of absolute positioning
   */
  base?: React.RefObject<View>;
  /** @default document.body */
  mountNode?: HTMLElement;
  /**
   * zIndex of the wrapper node the portal will render on, optional
   */
  zIndex?: string;
  /**
   * if you wish to set different position attribute for
   * the wrapper node the portal will render on, add this
   * optional, only applied if zIndex is provided, defaults to 'absolute'
   */
  position?: "relative" | "absolute" | "fixed";
  children: React.ReactNode;
  style?: ViewStyle;
};

/**
 * This portal component is SAFE for SSR.
 *
 * On the browser, it will initially mount the children
 * onto a detached DOM element. Once mounted, it will attach
 * the element onto the given mountNode OR document.body
 *
 * @see https://reactjs.org/docs/portals.html
 */
export default function Absolute(props: AbsoluteProps) {
  const ref = useRef(getInitialRef());
  const [{ x, y, width, height, hasFinishedLayout }, setLayout] =
    useState<LayoutRectangle>({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      hasFinishedLayout: false,
    });
  const id = useId();

  const { children, mountNode, base, zIndex, position, style } = props;

  useEffect(() => {
    const node = ref.current;
    if (node) {
      const parent =
        mountNode instanceof HTMLElement ? mountNode : document.body;

      if (zIndex) {
        node.style.zIndex = zIndex;
        node.style.position = position ? position : "absolute";
      }

      parent.appendChild(node);

      return () => {
        try {
          parent.removeChild(node);
        } catch {}
      };
    }
    // explicitly returning undefined to get rid of lint warnings - ts(7030)
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when mountNode changes
  }, [mountNode]);

  useEffect(() => {
    if (base && base.current) {
      const baseRefNode = findNodeHandle(base.current);

      const observer = observeRect(baseRefNode, (baseRect, basePagePos) => {
        setLayout({
          x: basePagePos.pageX,
          y: basePagePos.pageY,
          width: baseRect.width,
          height: baseRect.height,
          hasFinishedLayout: true,
        });
      });

      observer.observe();

      return observer.unobserve;
    }

    return () => undefined; // no op, has not been initialized yet
  }, [base]);

  if (!ref.current) {
    return null;
  }

  // This checks if the base node is actually in the viewport
  // or on the document. If it is to the right of the viewport limit
  // (x > viewportWidth) or it is to the bottom of the current document
  // height (y > document.height), the absolute component should not render
  // since it will cause unintended overflow. It will render it once
  // the base node is positioned inside the document.
  const viewportWidth = window.innerWidth;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );

  if (
    hasFinishedLayout &&
    x <= viewportWidth - width &&
    y <= documentHeight - height
  ) {
    return createPortal(
      <View
        key={id}
        style={{
          position: "absolute",
          // @ts-ignore
          pointerEvents: "none",
          top: y,
          left: x,
          width,
          height,
          ...style,
        }}
      >
        {children}
      </View>,
      ref.current
    );
  }

  return createPortal(children, ref.current);
}

const canUseDom =
  typeof window !== "undefined" && typeof window.document !== "undefined";

function getInitialRef(): HTMLDivElement | null {
  return canUseDom ? document.createElement("div") : null;
}

interface Styleable {
  style?: ViewStyle;
}

export function createAbsoluteComponent<T extends Styleable>(
  RawComponent: React.ComponentType<T>
) {
  function AbsoluteComponent(
    props: React.ComponentProps<typeof RawComponent> & AbsoluteProps
  ) {
    const { base, mountNode, ...rest } = props;

    return (
      <Absolute base={base} mountNode={mountNode}>
        <RawComponent {...(rest as T)} style={[styles.abs, rest.style]} />
      </Absolute>
    );
  }

  return AbsoluteComponent;
}

const styles = StyleSheet.create({
  abs: {
    position: "absolute",
  },
});
