import { useCallback } from "react";

import bowser from "bowser";
import { resolve } from "url";

import { useAuth } from "../auth";
import config from "../config";
import { useLocale } from "../locale/contexts/LocaleContext";
import ApiError from "./ApiError";
import {
  ApiOption,
  ApiResponse,
  ApiResult,
  ResponseError,
  ResponseStatus,
} from "./types";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function useClientApi<Response = unknown, Request = unknown>(
  option: ApiOption
) {
  const { getToken, isAuthenticated } = useAuth();
  const locale = useLocale();

  const { domain, method, path, withAuth = true, localePrefix = true } = option;
  const { apiHost } = config;

  return useCallback(
    async (payload: Request = {} as Request): Promise<ApiResult<Response>> => {
      let authServiceToken: string | undefined;
      if (withAuth && isAuthenticated) {
        authServiceToken = await getToken();
      }

      const options: RequestInit = {
        headers: defaultHeaders,
        method,
      };

      if (method === "post") {
        // Quick fix to pass `trackingSpec` outside of `data`
        let trackingSpec: any = undefined;
        // @ts-ignore
        if ("trackingSpec" in payload) {
          const browser = bowser
            .getParser(window.navigator.userAgent)
            .getBrowser();

          // @ts-ignore
          trackingSpec = Object.assign({}, payload.trackingSpec, {
            webUrl: window.location.href,
            webReferrer: null,
            webBrowser: browser.name,
            webBrowserVersion: browser.version,
          });

          // @ts-ignore
          delete payload.trackingSpec;
        }

        options.body = JSON.stringify({
          clientInterface: "desktop",
          context: { authServiceToken },
          data: payload,
          fields: [],
          trackingSpec,
        });
      }

      const apiUrl = resolve(
        apiHost[domain],
        ((localePrefix ? locale : "") + "/" + path).replace(/\/+/, "/")
      );
      const res = await fetch(apiUrl, options)
        .then<ApiResponse<Response>>((res) => res.json())
        .catch(() => null);

      /**
       * Type guard for 404, play framework html error, and any other error resulting
       * in non-json return type
       */
      if (typeof res !== "object" || res === null) {
        return {
          success: false,
          error: new TypeError("Api call failed"),
        };
      }

      // Public API
      if ("errorType" in res) {
        const { errorType } = res;

        let errorMessage = res.userErrorMessage;
        if (errorType === ResponseError.NOT_AUTHORIZED) {
          errorMessage = res.errorMessage;
        }

        return {
          success: false,
          error: new ApiError(errorType, errorMessage),
        };
      }

      // CTV API
      if ("status" in res && res.status !== ResponseStatus.OK) {
        const { status, errorMessage } = res;

        return {
          success: false,
          error: new ApiError(status, errorMessage),
        };
      }

      return {
        success: true,
        data: res.data,
        // Quick inject trackingSpec
        trackingSpec: res.trackingSpec,
      };
    },
    [
      domain,
      method,
      path,
      isAuthenticated,
      locale,
      getToken,
      apiHost,
      localePrefix,
      withAuth,
    ]
  );
}
