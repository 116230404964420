"use client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Permission, useAuth } from "@ctv/core";
import { ApiResult } from "@ctv/core/api/types";
import { convert } from "@ctv/core/utils/currency";

import { CreditLimitResponse, useGetCreditLimit } from "./apis";
import { CreditLimit, HasViewPermission, NoViewPermission } from "./types";

type Context = {
  data: NoViewPermission | HasViewPermission | undefined;
  isFetching: boolean;
  fetchCreditLimit: () => void;
};

const CreditLimitContext = createContext<Context>({
  data: undefined,
  isFetching: false,
  fetchCreditLimit: () => {},
});

export function useCreditLimit() {
  return useContext(CreditLimitContext);
}

export function CreditLimitProvider(props: PropsWithChildren<{}>) {
  const [creditLimit, setCreditLimit] = useState<CreditLimit["data"]>();
  const [isFetching, setIsFetching] = useState(false);

  const { user, isAuthenticated } = useAuth();
  const getCreditLimit = useGetCreditLimit();

  function postProcessCreditLimit(res?: ApiResult<CreditLimitResponse>) {
    if (res?.success) {
      const hasPermission: boolean =
        user?.has(Permission.BILLING_READ) ?? false;

      if (!hasPermission) {
        setCreditLimit({
          hasPermission: false,
          remainingCreditLimit: {
            amount: null,
            status: res.data.remainingCreditLimit.status,
          },
          remainingPercentage: null,
          totalCreditLimit: null,
        } as NoViewPermission);
      } else if (hasPermission) {
        setCreditLimit({
          hasPermission: true,
          remainingCreditLimit: {
            amount: convert(res.data.remainingCreditLimit.amount!),
            status: res.data.remainingCreditLimit.status,
          },
          remainingPercentage: res.data.remainingPercentage,
          totalCreditLimit: {
            amount: convert(res.data.totalCreditLimit!.amount),
          },
        } as HasViewPermission);
      }
    }
  }

  function fetchData() {
    if (!isAuthenticated) return;

    setIsFetching(true);
    getCreditLimit({})
      .then(postProcessCreditLimit)
      .finally(() => {
        setIsFetching(false);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  useEffect(() => fetchData(), []);

  return (
    <CreditLimitContext.Provider
      value={{ data: creditLimit, fetchCreditLimit: fetchData, isFetching }}
    >
      {props.children}
    </CreditLimitContext.Provider>
  );
}
