"use client";
import {
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useRef,
} from "react";
import { TouchableOpacity } from "react-native";

import { useFocus } from "@ctv/momentum/hooks";
import { ViewSeo } from "../../View";
import cssStyles from "./Input.module.css";

type AriaInvalidMessage = "false" | "true" | "grammar" | "spelling" | undefined;

type Variant = "formal" | "minimal";

type Size = "medium" | "small";

export type InputRef = HTMLInputElement;

export type InputPropsSeo = {
  /**
   * If true, text is greyed out
   */
  disabled?: boolean;
  /**
   * Element placed after the children.
   * Icon should be resized according to spec
   * - `medium`: 16px
   * - `small`: 12px
   */
  endIcon?: ReactElement;
  /**
   * Error state
   * @default false
   */
  error?: boolean;
  /**
   * ID of an element describing the help text
   */
  helperId?: string;
  /**
   * Custom style applied to the input element
   */
  inputClassName?: string;
  /**
   * ID of an element describing the label
   */
  labelId?: string;
  /**
   * Size of the input
   */
  size?: Size;
  /**
   * Element placed before the children.
   * Icon should be resized according to spec
   * - `medium`: 24px
   * - `small`: 16px
   */
  startIcon?: ReactElement;
  /**
   * Custom style applied to the root element
   */
  className?: string;
  /**
   * @default 'formal'
   */
  variant?: Variant;
  /**
   * To enable icon start focus feedback
   */
  isStartIconClickable?: boolean;
  /**
   * To enable icon end focus feedback
   */
  isEndIconClickable?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const iconSizeMap = {
  medium: [24, 16],
  small: [16, 12],
};

export default forwardRef<HTMLInputElement, InputPropsSeo>(function InputSeo(
  props,
  ref
) {
  const {
    disabled = false,
    readOnly = false,
    error = false,
    // errorId,
    helperId,
    endIcon,
    startIcon,
    labelId,
    onBlur,
    onFocus,
    size = "medium",
    variant = "formal",
    className,
    isStartIconClickable,
    isEndIconClickable,
    ...textInputProps
  } = props;

  const localInputRef = useRef<HTMLInputElement>(null);
  const [startSize, endSize] = iconSizeMap[size];
  const [focused, focusHandler] = useFocus({ onBlur, onFocus });

  const [containerStyle, focusedStyle] = getVariantStyle(variant, size);

  const usedRef = ref || localInputRef;

  return (
    <ViewSeo
      align="center"
      row
      spacing="xs"
      className={[
        cssStyles.root,
        containerStyle,
        error && cssStyles.rootError,
        focused && focusedStyle,
        className,
      ].join(" ")}
    >
      {startIcon &&
        (isStartIconClickable ? (
          // @ts-expect-error
          <TouchableOpacity onPress={() => ref?.current?.focus()}>
            {cloneElement(startIcon, { height: startSize, width: startSize })}
          </TouchableOpacity>
        ) : (
          cloneElement(startIcon, { height: startSize, width: startSize })
        ))}
      <input
        {...textInputProps}
        {...focusHandler}
        ref={usedRef}
        aria-describedby={helperId}
        aria-errormessage={error ? helperId : undefined}
        aria-invalid={String(error) as AriaInvalidMessage}
        aria-labelledby={labelId}
        disabled={disabled}
        readOnly={disabled || readOnly}
        className={[
          cssStyles.input,
          cssStyles[size],
          textInputProps.inputClassName,
        ].join(" ")}
      />
      {endIcon &&
        (isEndIconClickable ? (
          // @ts-expect-error
          <TouchableOpacity onPress={() => usedRef?.current?.focus()}>
            {cloneElement(endIcon, { height: endSize, width: endSize })}
          </TouchableOpacity>
        ) : (
          cloneElement(endIcon, { height: endSize, width: endSize })
        ))}
    </ViewSeo>
  );
});

function getVariantStyle(variant: Variant, size: Size) {
  const small = size === "small";

  switch (variant) {
    case "formal":
      return [
        [cssStyles.formal, small && cssStyles.formalSmall].join(" "),
        [cssStyles.formalFocused, small && cssStyles.formalSmallFocused].join(
          " "
        ),
      ] as const;
    case "minimal":
      return [
        [cssStyles.minimal, small && cssStyles.minimalSmall].join(" "),
        [cssStyles.minimalFocused, small && cssStyles.minimalSmallFocused].join(
          " "
        ),
      ] as const;
  }
}
