import { ComponentType } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import ChevronLeft from "@traveloka/icon-kit-web/react/IcSystemChevronLeft16";
import ChevronRight from "@traveloka/icon-kit-web/react/IcSystemChevronRight16";

import Text from "../Text";
import Token from "../Token";
import View from "../View";

export type CalendarHeaderProps = {
  nextDisabled?: boolean;
  onNextPress?(): void;
  onPrevPress?(): void;
  prevDisabled?: boolean;
  text: string;
};

export default function CalendarHeader(props: CalendarHeaderProps) {
  const { nextDisabled, onNextPress, onPrevPress, prevDisabled, text } = props;

  return (
    <View align="center" row justify="between" spacing="m" style={styles.root}>
      <Text variant="title-2" style={styles.text}>
        {text}
      </Text>
      <IconTouchable
        disabled={prevDisabled}
        onPress={onPrevPress}
        Icon={ChevronLeft}
      />
      <IconTouchable
        disabled={nextDisabled}
        onPress={onNextPress}
        Icon={ChevronRight}
      />
    </View>
  );
}

type IconTouchableProps = {
  disabled?: boolean;
  Icon: ComponentType<{ color?: string }>;
  onPress?(): void;
};

function IconTouchable(props: IconTouchableProps) {
  const { disabled, Icon, onPress } = props;

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={styles.button}
    >
      <Icon color={disabled ? Token.color.lightSecondary : undefined} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
  text: {
    marginEnd: "auto",
  },
  // improve hit box
  button: {
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
  },
});
