import { EmploymentDetail, Profile } from "./types";

export const GET_PROFILE = "/api/v1/authorization/profile";
export const UPDATE_PROFILE = "/api/v1/authorization/update-profile";
export const RESET_MY_PASSWORD = "/api/v1/authorization/reset-my-password";

export type ProfileResponse = {
  employmentDetail: EmploymentDetail;
  profile: Profile;
};

export type ResetPasswordResponse = {
  errorMessage: Nullable<string>;
  status: string;
};

export type UpdateProfileRequest = Pick<
  Profile,
  "nationality" | "phone" | "phoneCountryCode" | "profileDetails"
>;

export type UpdateProfileResponse = {
  status: boolean;
};
