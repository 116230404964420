export const localeCookieKey = "NEXT_LOCALE";

export enum Locale {
  ENID = "en-id",
  IDID = "id-id",
  ENTH = "en-th",
  THTH = "th-th",
  ENVN = "en-vn",
  VNVN = "vi-vn",
}
export const DEFAULT_LOCALE = Locale.ENID;
