import { Children, ReactNode, forwardRef } from "react";
import {
  View as RNView,
  ViewProps as RNViewProps,
  StyleSheet,
  ViewStyle,
} from "react-native";

import Token, { Spacing } from "../Token";
import { intersperse } from "../utils";

export type ViewRef = RNView;

export type ViewProps = {
  children?: ReactNode;
  /**
   * Defines `align-items` style property.
   * @default 'stretch'
   */
  align?: "start" | "end" | "center" | "stretch" | "baseline";
  /**
   * Defines `justify-content` style property.
   * @default 'start'
   */
  justify?: "start" | "end" | "center" | "around" | "between" | "evenly";
  /**
   * Set`flex-direction` as `row-reverse` or `column-reverse`
   */
  reverse?: boolean;
  /**
   * Set `flex-direction` as `row` or `column`
   */
  row?: boolean;
  /**
   * Defines the space between the children.
   */
  spacing?: Spacing;
} & RNViewProps;

export default forwardRef<RNView, ViewProps>(function View(props, ref) {
  const { align, justify, spacing, reverse, row, style, ...rest } = props;

  let children = props.children;
  if (spacing !== undefined) {
    const property = row ? "width" : "height";
    children = intersperse(Children.toArray(props.children), (index) => (
      <div
        aria-hidden
        key={"s." + index}
        style={{ flexShrink: 0, [property]: Token.spacing[spacing] }}
      />
    ));
  }

  let directionStyle: ViewStyle = {};
  if (row && reverse) {
    directionStyle = directionStyles["row-reverse"];
  } else if (row) {
    directionStyle = directionStyles["row"];
  } else if (reverse) {
    directionStyle = directionStyles["column-reverse"];
  }

  const rootStyles = [
    directionStyle,
    typeof align === "string" && alignmentStyles[align],
    typeof justify === "string" && justifyStyles[justify],
    style,
  ];

  return (
    <RNView {...rest} ref={ref} style={rootStyles}>
      {children}
    </RNView>
  );
});

const directionStyles = StyleSheet.create({
  row: { flexDirection: "row" },
  "row-reverse": { flexDirection: "row-reverse" },
  "column-reverse": { flexDirection: "column-reverse" },
});

const alignmentStyles = StyleSheet.create({
  start: { alignItems: "flex-start" },
  end: { alignItems: "flex-end" },
  center: { alignItems: "center" },
  baseline: { alignItems: "baseline" },
  stretch: { alignItems: "stretch" },
});

const justifyStyles = StyleSheet.create({
  start: { justifyContent: "flex-start" },
  end: { justifyContent: "flex-end" },
  center: { justifyContent: "center" },
  around: { justifyContent: "space-around" },
  between: { justifyContent: "space-between" },
  evenly: { justifyContent: "space-evenly" },
});
