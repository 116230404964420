"use client";
import { Text, Token } from "@ctv/momentum";
import React from "react";
import { StyleSheet, View } from "react-native";

type Props = {
  password: string;
  show: boolean;
};

export default function NewPasswordValidity(props: Props) {
  const { password, show } = props;

  const { validate, isRequiredValidationValid } = validatePassword(password);

  if (!show) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text variant="ui-tiny" ink={textInk(validate.length)}>
        - At least 8 characters in length
      </Text>
      <Text variant="ui-tiny" ink={textInk(isRequiredValidationValid)}>
        Contain at least 3 of the following 4 type of characters:
      </Text>
      <Text variant="ui-tiny" ink={textInk(validate.lowercase)}>
        - Lower case letters (a-z)
      </Text>
      <Text variant="ui-tiny" ink={textInk(validate.uppercase)}>
        - Upper case letters (A-Z)
      </Text>
      <Text variant="ui-tiny" ink={textInk(validate.number)}>
        - Numbers (i.e. 0-9)
      </Text>
      <Text variant="ui-tiny" ink={textInk(validate.special)}>
        - Special characters (e.g. !@#$%^&*)
      </Text>
    </View>
  );
}

function textInk(isValid?: boolean) {
  return isValid ? "positive" : "alert";
}

type Validation = Partial<{
  length: boolean;
  lowercase: boolean;
  uppercase: boolean;
  number: boolean;
  special: boolean;
}>;
export function validatePassword(password: string) {
  const validate: Validation = {};
  validate.length = password.length >= 8;
  validate.lowercase = /[a-z]/.test(password);
  validate.uppercase = /[A-Z]/.test(password);
  validate.number = /[0-9]/.test(password);
  validate.special = /[!@#$%^&*]/.test(password);

  let countRequiredValidation = 0;
  Object.keys(validate)
    .filter((v) => v !== "length")
    .forEach((key) => {
      if (validate[key as keyof Validation]) {
        countRequiredValidation += 1;
      }
    });

  const isRequiredValidationValid = countRequiredValidation >= 3;
  const isLengthValidationValid = validate.length;

  return {
    validate,
    isValid: isRequiredValidationValid && isLengthValidationValid,
    isRequiredValidationValid,
    isLengthValidationValid,
  };
}

const styles = StyleSheet.create({
  container: {
    marginBottom: Token.spacing.m,
  },
});
