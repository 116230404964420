"use client";
import bowser from "bowser";
import { parse } from "qs";
import { useCallback, useMemo } from "react";

import {
  ATTACHMENT_ACTIVITY,
  DATA_TRACKING_API,
  LANDING_PAGE,
  ORS_PAGE,
  USER_ACTIVITY,
} from "./api";
import { getVisitId, incrementEventSeq } from "./utils";

import { ApiResult } from "../api/types";
import useClientApi from "../api/useClientApi";
import { useAuth } from "../auth";
import { useClientInterface } from "../providers/client-interface/ClientInterfaceContext";

export type TrackingEvent = {
  eventName: string;
  /**
   * Use semantic versioning, initial value should be 1.0.0
   *
   * If you modify event in a non-backward compatible way (eg. change type from INTEGER to STRING),
   * increment X, and set Y to 0.
   *
   * If you modify event in a backward compatible way (eg. add new field),
   * increment Y.
   */
  eventVersion?: string;
  /**
   * Current page name or screen name
   */
  pageName: string;
  /**
   * Title on the screen or page
   */
  pageTitle?: string;
  /**
   * Previous `pageName`, if the event does not change page no need to set this
   */
  pageReferrer?: string;
  /**
   * Parent category of the page
   */
  pageCategory: string;
  /**
   * A category is a name that you supply as a way to group objects that you want to track.
   *
   * More info: https://support.google.com/analytics/answer/1033068#Anatomy
   */
  actionCategory?: string;
  /**
   * A category is a name that you supply as a way to group objects that you want to track.
   *
   * More info: https://support.google.com/analytics/answer/1033068#Anatomy
   */
  actionName?: string;
  /**
   * A category is a name that you supply as a way to group objects that you want to track.
   *
   * More info: https://support.google.com/analytics/answer/1033068#Anatomy
   */
  actionLabel?: Record<string, any>;
  /**
   * A category is a name that you supply as a way to group objects that you want to track.
   *
   * More info: https://support.google.com/analytics/answer/1033068#Anatomy
   */
  actionValue?: string;
  /**
   * Indicates whether an action is an interaction or not.
   */
  nonInteraction?: boolean;
} & Dictionary<any>;

type TrackerModifierFn = {
  send(options?: ModifierOptions): Promise<ApiResult<unknown>>;
};

type ModifierOptions = {
  maxWaitMs: number;
};

const defaultOptions: ModifierOptions = {
  maxWaitMs: 300,
};
const events: object[] = [];
let timeout: number | undefined;

const TRACKING_INTERVAL = 10000;
const [visitId, sessEventSeq] = getVisitId();
let eventSeq = sessEventSeq;

/**
 * Each path has no difference in the front-end side.
 * Only to provide for the back-end (leaner for them) */

const path = {
  "landing-page": LANDING_PAGE,
  "user-activity": USER_ACTIVITY,
  "ors-page": ORS_PAGE,
  "attachment-activity": ATTACHMENT_ACTIVITY,
};

/**
 *
 * @param key - use this key to track via backend url. If not provided, it will directly hit the data team tracking API
 * @returns
 */
export default function useTracker(key?: keyof typeof path) {
  // TODO: get these values from provider once multi locale and currency are enabled
  const language = "en";
  const country = "ID";
  const currency = "IDR";
  const clientInterface = useClientInterface();

  const fetch = useClientApi(
    key
      ? // CTV Backend Tracking API
        {
          domain: "management",
          method: "post",
          path: path[key],
        }
      : // Data Team Tracking API
        {
          domain: "data",
          method: "post",
          path: DATA_TRACKING_API,
          localePrefix: false,
        }
  );
  const { user } = useAuth();

  const trackerModifier: TrackerModifierFn = useMemo(
    () => ({
      // Send tracking data immediately, this returns a promise that can be await-ed to make sure
      // tracking is sent before doing some action (usually reload-type navigation). We didn't throw
      // if failed here because tracking shouldn't block user action. If user want to handle error case,
      // they can do it manually by checking res.success property
      send(options = defaultOptions) {
        const { maxWaitMs } = options;
        const latestEvent = events.pop();
        const date = new Date();
        const apiCall = fetch({
          sentTimestamp: date.getTime(),
          timezoneOffset: date.getTimezoneOffset() * -60000,
          events: [latestEvent],
          currency,
        });
        const apiLatencyBuffer: Promise<ApiResult<unknown>> = new Promise(
          (resolve) => {
            setTimeout(
              // @ts-ignore
              resolve({
                success: false,
                error: new Error(
                  `Tracking API took longer than ${maxWaitMs}ms`
                ),
              }),
              maxWaitMs
            );
          }
        );

        // We return Promise.race to make sure tracking API doesn't block navigation events
        // for too long. 300ms is acceptable upper-bound latency for tracking API on regular
        // 3G network (based on unscientific tests)
        return Promise.race([apiCall, apiLatencyBuffer]);
      },
    }),
    [fetch]
  );

  return useCallback(
    (event: string, data: TrackingEvent): TrackerModifierFn => {
      const timestamp = Date.now();
      const userAgent = window.navigator.userAgent;
      const browser = bowser.getParser(userAgent).getBrowser();
      let userLoginId;
      let sessionId;

      if (user) {
        userLoginId = user.email;
        sessionId = `${user.email};${user.loginTime}`;
      }

      incrementEventSeq(++eventSeq);

      events.push({
        event,
        data: Object.assign(
          {},
          {
            eventBusinessUnit: "CORP_B2B",
            eventVersion: "1.0.0",

            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            clientTimestamp: timestamp,

            intf: clientInterface,
            userAgent: userAgent,

            userSettingsCountry: country,
            userSettingsLang: language,
            userSettingsCurrency: currency,

            userCorporateUserId: user?.email,
            userCorporateId: user?.corporateCode,
            userCorporateName: user?.corporateName,

            webUrl: window.location.href,
            webReferrer: window.location.href,
            webBrowser: browser.name,
            webBrowserVersion: browser.version,

            visitId,
            eventSeq,

            queryParameters: parse(window.location.search),

            userLoginId,
            sessionId,
          },
          data
        ),
        timestamp,
      });

      if (timeout === undefined) {
        timeout = window.setTimeout(async () => {
          const collectedEvents = events.splice(0);
          const date = new Date();
          const res = await fetch({
            sentTimestamp: date.getTime(),
            timezoneOffset: date.getTimezoneOffset() * -60000,
            events: collectedEvents,
            currency,
          });

          if (!res.success) {
            // If tracking data is failed to be sent, add them back in the same order
            // as it was previously, to make sure that order of events is consistent
            events.unshift(...collectedEvents);
          }

          // reset timeout value so we can schedule another batch later
          timeout = undefined;
        }, TRACKING_INTERVAL);
      }

      return trackerModifier;
    },
    [language, country, currency, clientInterface, user, fetch, trackerModifier]
  );
}
