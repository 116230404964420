import { v4 as uuid } from "uuid";

import SessionStorage from "../utils/SessionStorage";

const VISIT_ID_SESSION_STORAGE_KEY = "$trck$";

export function getVisitId(): [string, number] {
  const visitId = SessionStorage.get(VISIT_ID_SESSION_STORAGE_KEY);

  if (visitId) {
    const eventSeq = Number(
      SessionStorage.get(VISIT_ID_SESSION_STORAGE_KEY + visitId) || 0
    );

    return [visitId, eventSeq];
  }

  const newVisitId = uuid();
  SessionStorage.set(VISIT_ID_SESSION_STORAGE_KEY, newVisitId);
  SessionStorage.set(VISIT_ID_SESSION_STORAGE_KEY + newVisitId, 0);

  return [newVisitId, 0];
}

export function incrementEventSeq(newEventSeq: number) {
  const [visitId] = getVisitId();
  SessionStorage.set(VISIT_ID_SESSION_STORAGE_KEY + visitId, newEventSeq);
}

export function removeVisitId() {
  const visitId = SessionStorage.get(VISIT_ID_SESSION_STORAGE_KEY);

  if (visitId) {
    SessionStorage.remove(VISIT_ID_SESSION_STORAGE_KEY);
    SessionStorage.remove(VISIT_ID_SESSION_STORAGE_KEY + visitId);
  }
}
